import ApiClient from './ApiClient';

class RinsedReceiptEmails extends ApiClient {
  constructor() {
    super('rinsed/receipt_emails', { accountScoped: true });
  }

  post(payload) {
    return axios.post(this.url, payload);
  }
}

export default new RinsedReceiptEmails();
