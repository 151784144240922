import ApiClient from './ApiClient';

class RinsedUjetRecordings extends ApiClient {
  constructor() {
    super('rinsed/ujet_recordings', { accountScoped: true });
  }

  get(query = '', page = 1) {
    return axios.get(this.url + '?query=' + query + '&page=' + page);
  }
}

export default new RinsedUjetRecordings();
