<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        header-title="Create Email Conversation"
      />

      <form class="row" :action="actionURL" method="post">
        <div class="medium-12 columns">
          <label :class="{ error: $v.email.$error }">
            Email
            <input
              v-model.trim="email"
              type="text"
              name="email"
              placeholder="email@example.com"
              @input="$v.email.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label>
            Subject (optional)
            <input
              v-model.trim="subject"
              type="text"
              name="subject"
              placeholder="subject"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                  $v.email.$invalid
              "
              button-text="Create"
            />
            <button class="button clear" @click.prevent="onClose">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
      subject: '',
      vertical: 'bottom',
      horizontal: 'center',
      show: true,
    };
  },
  validations: {
    email: {
      required,
    },
  },
  computed: {
    actionURL() {
      const accountId = window.location.pathname.split('/')[3];
      return `/rinsed/accounts/${accountId}/manual_emails`;
    },
  },
};
</script>
