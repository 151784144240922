import UjetRecordingsTable from './components/UjetRecordingsTable';
import { frontendURL } from '../../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/recordings'),
    name: 'ujet_recordings_home',
    roles: ['administrator'],
    component: UjetRecordingsTable,
  },
];
