import RinsedReceiptEmails from '../../../api/rinsed_receipt_emails';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../../mutation-types';

export const state = {
  records: [],
};

export const getters = {};

export const actions = {
  post: async ({ commit }, payload = {}) => {
    commit(types.default.SET_RINSED_SENDING_RECEIPT_EMAIL_STATUS, true);
    try {
      const response = await RinsedReceiptEmails.post(payload);
      commit(types.default.SET_RINSED_SEND_RECEIPT_EMAIL, response.data);
      commit(types.default.SET_RINSED_SENDING_RECEIPT_EMAIL_STATUS, false);
    } catch (error) {
      commit(types.default.SET_RINSED_SENDING_RECEIPT_EMAIL_STATUS, false);
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_RINSED_SENDING_RECEIPT_EMAIL_STATUS]($state, data) {
    $state.receiptEmail = data;
  },
  [types.default.SET_RINSED_SEND_RECEIPT_EMAIL]: MutationHelpers.create,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
