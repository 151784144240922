import UjetRecordingsApi from '../../../api/rinsed_ujet_recordings';
import * as types from '../../mutation-types';

export const state = {
  ujetRecordings: [],
};

export const getters = {
  get($state) {
    return $state.ujetRecordings;
  },
};

export const actions = {
  get: async ({ commit }, { query, page } = {}) => {
    try {
      const response = await UjetRecordingsApi.get(query, page);
      commit(types.default.SET_UJET_RECORDINGS, response.data.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      this.showAlert(errorMessage);
    }
  },
};

export const mutations = {
  [types.default.SET_UJET_RECORDINGS]($state, data) {
    $state.ujetRecordings = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
